.variants {
  width: 100%;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-gap: 10px;
  padding: 16px;
  border-radius: 8px;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.5);
  }


  @media (min-width: 576px) {
    grid-gap: 35px;
    min-height: 190px;
  }

  &__title,
  &__descr {
    position: relative;
    z-index: 2;
  }
}
