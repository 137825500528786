.configuration {
  width: 100%;

  &__title {
    margin-bottom: 28px;
  }

  &__list {
    margin-bottom: 38px;
    display: flex;
    flex-direction: column;

    @media (min-width: 1024px) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 40px;
    }
  }

  &__list-item {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    grid-gap: 0 50px;
    padding: 31px 0;
    border-bottom: 1px solid rgba(49, 49, 51, 0.2);

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
      border-bottom: transparent;
    }

    @media (min-width: 1024px) {
      padding: 0;
      border-bottom: transparent;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    grid-gap: 5px;
    flex-grow: 1;
  }

  &__info-pricelist {
    display: flex;
    align-items: center;
    grid-gap: 15px;
  }

  &__choose {
    width: calc(100% + 16px);
    padding-right: 16px;
    display: grid;
    align-items: center;
    grid-template-columns: repeat(3, 220px);
    grid-gap: 12px;
    overflow-x: auto;

    @media (min-width: 768px) {
      width: 100%;
      grid-template-columns: repeat( 3, 1fr);
      padding-right: 0;
    }
  }
}
