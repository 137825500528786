.choose {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;

  &__info {
    position: relative;
  }

  &__image {
    height: clamp(120px, 18.056vw, 260px);

    picture,
    img {
      width: 100%;
      height: 100%;
      border-radius: 4px;
    }
  }

  &__made {
    position: absolute;
    top: 5px;
    left: 7px;
    display: flex;
    align-items: center;
    grid-gap: 4px;
  }

  &__made-flag {
    width: clamp(28px, 4.167vw, 60px);
    height: clamp(28px, 4.167vw, 60px);

    picture,
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }
}
