.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 25px;

  &__title {
    width: 100%;
    text-align: left;
  }

  &__labels {
    width: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: 27px;
  }

  &__input {
    padding: 10px 17px;
    background-color: var(--input-bg);
    border-radius: 5.7952px;

    &::placeholder {
      font-size: 16px;
      line-height: 160%;
      color: rgba(0, 0, 0, 0.4);
    }
  }
}
