@mixin font-face($font-family, $url, $weight, $style) {
  @font-face {
    font-family: "#{$font-family}";
    src: url('../fonts/#{$url}.woff2') format("woff2"),
         url('../fonts/#{$url}.woff') format("woff");
    font-weight: #{$weight};
    font-display: swap;
    font-style: $style;
  }
}

@include font-face("Inter", "../fonts/Inter-Regular", 400, normal);
@include font-face("Inter", "../fonts/Inter-Medium", 500, normal);
@include font-face("Inter", "../fonts/Inter-SemiBold", 600, normal);
@include font-face("Inter", "../fonts/Inter-Bold", 700, normal);
@include font-face("Inter", "../fonts/Inter-ExtraBold", 800, normal);






