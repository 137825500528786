.seo {
  padding: 57px 0;

  &__wrapp {
    display: flex;
    flex-direction: column;
    grid-gap: clamp(25px, 3.906vw, 40px);
  }

  &__block-list {
    width: 100%;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    grid-gap: 40px;


    @media (min-width: 1024px) {
      flex-direction: row;
      justify-content: space-between;

      li {
        width: 48%;
      }
    }
  }

  &__consultation {
    @media (min-width: 768px) {
      padding: 30px 50px;

      .consultation__box {
        max-width: 940px;
        grid-gap: 30px;
        align-items: initial;
        padding: 0 60px;
      }

      .consultation__img {
        width: clamp(220px, 19.444vw, 280px);
      }

      .consultation__image {
        left: 50%;
        transform: translateX(-50%);
        width: clamp(220px, 19.444vw, 280px);
        height: auto;
      }

      .consultation__info {
        max-width: 430px;
        padding: 60px 0;
      }

      .consultation__title {
        text-align: left;
      }
    }
  }
}
