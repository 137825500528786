.information {
  position: relative;

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    picture,
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  &__wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 110px 0 58px;
    grid-gap: 36px;
    z-index: 1;

    @media (min-width: 1024px) {
      // flex-direction: row;
      // align-items: center;
      // justify-content: space-evenly;
      // padding: 110px 0;
    }
  }

  &__left {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 26px;

    @media (min-width: 576px) {
      grid-gap: 64px;
    }
  }

  &__right {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 44px;

    // @media (min-width: 1024px) {
    //   flex-direction: column-reverse;
    // }
  }

  &__title {
    span {
      margin-top: 12px;

      @media (min-width: 576px) {
        margin-top: 25px;
      }
    }
  }

  &__list-item {
    list-style: disc;
  }
}
