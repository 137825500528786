.modal {
  --transition-time: 0.3s;
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 1000;
  background: rgba(49, 49, 51, 0.8);
  backdrop-filter: blur(5px);
	overflow-y: auto;
	overflow-x: hidden;
	text-align: center;
	opacity: 0;
	visibility: hidden;
	transition: opacity var(--transition-time), visibility var(--transition-time);

  &::before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    height: 100%;
  }


  &.is-open {
    position: fixed;
    opacity: 1;
    visibility: visible;
    transition: opacity var(--transition-time), visibility var(--transition-time);
  }

  &__container {
    position: relative;
    width: auto;
    margin: 50px auto;
    display: none;
    vertical-align: middle;
    background-color: var(--color-white);
    border-radius: 5px;
    cursor: default;
    text-align: center;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    border-radius: 20px;

    &.modal-open {
      display: inline-block;
    }
  }

  &__wrapper {
    max-width: 560px;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    padding: 30px;
    background-color: var(--color-white);
    border-radius: 5px;
    position: relative;

    @media screen and (max-width: 1240px) {
      width: 90vw;
    }
  }

  &__close {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    position: absolute;
    right: 10px;
    top: 10px;
    background-color: var(--color-white);
    transition: all 0.4s ease;
    cursor: pointer;

    svg {
      fill:  var(--color-main);
    }

    &:hover,
    &:active {
      svg {
        fill: var(--color-yellow);
      }
    }
  }
}







.fade {
  opacity: 0;
  transition: opacity var(--transition-time);
}

.fade.animate-open {
  opacity: 1;
  transition: opacity var(--transition-time);
}

.fadeInUp {
  opacity: 0;
  transform: translateY(-100px);
  transition: opacity var(--transition-time), transform var(--transition-time);
}

.fadeInUp.animate-open {
  opacity: 1;
  transform: translateY(0);
  transition: opacity var(--transition-time), transform var(--transition-time);
}

.disable-scroll {
  position: relative;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
}
