.burger {
  position: relative;
  padding: 0 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 3px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: var(--color-yellow);
  cursor: pointer;
  z-index: 50;

  span {
    width: 100%;
    border-top: 2px solid var(--color-main);
    transition: all 0.4s ease;
  }

  @media (min-width: 1024px) {
    display: none;
  }

  &.active {
    position: fixed;
    right: 16px;

    span:nth-child(1) {
      transform: rotate(45deg) translate(3px, 4px);
    }

    span:nth-child(2) {
      opacity: 0;
    }

    span:nth-child(3) {
      transform: rotate(-45deg) translate(3px, -4px);
    }
  }
}
