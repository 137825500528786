.mats {
  margin-top: 47px;

  @media (min-width: 576px) {
    margin-top: 65px;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    grid-gap: 25px;

    @media (min-width: 576px) {
      grid-gap: 40px;
      padding: 0 14px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 25px;

    @media (min-width: 768px) {
      display: grid;
      grid-template-columns: 55% 40%;
      grid-gap: 40px;
    }
  }

  &__variants {
    width: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: 12px;

    @media (min-width: 576px) {
      grid-row: span 2;
      grid-gap: 25px;
    }
  }

  &__details {
    max-width: 260px;
    display: flex;
    flex-direction: column;
    grid-gap: 20px;

    @media (min-width: 576px) {
      max-width: 100%;
    }
  }

  &__details-item {
    position: relative;
    padding-left: 25px;

    &::before {
      content: '';
      position: absolute;
      top: 5px;
      left: 0;
      width: 10px;
      height: 10px;
      background-color: #FCCE1C;
      border-radius: 50%;
    }
  }
}
