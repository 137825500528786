.gifts {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 25px;


  @media (min-width: 1024px) {
    grid-column: span 2;
  }

  &__slider {
    max-width: 100%;
  }

  &__title {
    text-align: center;
  }

  &__slide {
    @media (min-width: 1024px) {
      width: clamp(200px, 23.611vw, 340px);
    }
  }

  &__product {
    border-radius: 16px;
    width: 100%;
    height: clamp(120px, 18.056vw, 260px);

    picture,
    img {
      display: flex;
      width: 100%;
      height: 100%;
      border-radius: 4px;
    }
  }
}
