body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  position: relative;
  font-family: var(--font-family-inter);
  font-weight: 400;
  color: var(--color-main);
  overflow-x: hidden;
}

html {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}


* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a {
  display: inline-block;
  color: inherit;
  font-weight: inherit;
  font-style: inherit;
  font-family: inherit;
  text-decoration: none;
  cursor: pointer;
  margin: 0;
  width: fit-content;
}

button {
  background-color: transparent;
  border: none;
  display: inline-block;
  outline: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  line-height: initial;
  width: fit-content;
}

p {
  display: inline-block;
  padding: 0;
  margin: 0;
  line-height: initial;
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
  height: auto;
  object-fit: cover;
}

picture {
  display: inline-flex;
}

fieldset {
    padding: 0;
    outline: none;
    border: none;
}

input {
  width: 100%;
  outline: none;
  border: none;
}

textarea {
    width: 100%;
    outline: none;
    border: none;
}

label {
  display: block;
  position: relative;
  width: 100%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  margin: 0;
  appearance: none;
}

input[type="number"] {
  appearance: textfield;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  white-space: nowrap;
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
}

.body-scroll {
  overflow-y: hidden;
}

.wrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.main {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.container {
  max-width: 1472px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  padding: 0 16px;
}
