.gift {
  max-width: 248px;
  display: flex;
  align-items: center;
  padding: 2px;
  background-color: rgba(217, 217, 217, 0.8);
  border-radius: 5.73333px;

  @media (min-width: 768px) {
    padding: 10px 19px;
    max-width: 460px;
    min-height: 147px;
  }

  &__box {
    margin-left: -26px;
    flex-shrink: 0;
    filter: drop-shadow(4px 7px 15px rgba(89, 76, 27, 0.32));
    transform: matrix(0.98, -0.18, 0.22, 0.98, 0, 0);
    animation: boxbeat 1.5s ease infinite;

    @media (min-width: 768px) {
      margin-left: -70px;
    }

    picture,
    img {
      width: 94px;
      height: 92px;

      @media (min-width: 768px) {
        width: 163px;
        height: 147px;
      }
    }
  }
}

@keyframes boxbeat {
  from {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  to {
    transform: scale(1);
  }
}
