.mat {
  margin-top: 55px;

  &__wrapp {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-gap: 25px;

    @media (min-width: 1024px) {
      display: grid;
      grid-template-columns: 60% 40%;
      grid-template-columns: 2fr 1fr;
      padding: 0 16px;
      align-items: start;
    }
  }

  &__title {
    @media (min-width: 576px) {
      grid-column: span 2;
      width: 100%;
      text-align: left;
    }
  }

  &__details {
    width: 100%;
    margin-bottom: clamp(25px, 2.083vw, 30px);
    display: flex;
    flex-direction: column;
    grid-gap: 16px;
  }

  &__details-item {
    position: relative;
    padding-left: 45px;

    @media (min-width: 576px) {
      br {
        display: none;
      }
    }

    &::before {
      content: '';
      position: absolute;
      background-image: var(--mat-details);
      background-repeat: no-repeat;
      width: 29px;
      height: 29px;
      top: -5px;
      left: 0;
    }
  }

  &__consultation {
    margin: 0 auto 20px;
  }

  &__slider {
    position: relative;
    width: calc(100% + 32px);
    margin-left: -16px;

    @media (min-width: 768px) {
      width: 100%;
      margin-left: 0;
    }
  }

  &__slider-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    cursor: pointer;
    transition: all 0.4s ease;

    &:hover {
      transform: scale(1.1) translateY(-50%);
    }

    &--prev {
      left: 16px;
    }

    &--next {
      right: 16px;
    }
  }

  &__slider-pagination {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 25px;
    z-index: 10;
    display: flex;
    align-items: center;
    grid-gap: 10px;
  }

  &__information {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__products {
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 25px 50px;

    @media (min-width: 1024px) {
      grid-column: span 2;
    }
  }

  &__gift {
    @media (min-width: 1024px) {
      max-width: 600px;
      margin: 0 auto;
      grid-column: span 2;
    }

  }

  &__gifts {
    position: relative;
    width: calc(100% + 32px);
    padding: 33px 0 26px;
    background-color: var(--color-yellow);
    overflow-x: hidden;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;
      background-color: var(--color-yellow);
    }

    &::before {
      left: -99%;
    }

    &::after {
      right: -99%;
    }

    @media (min-width: 1024px) {
      width: 100%;
      grid-column: span 2;
      overflow: initial;
    }
  }

  &__btn {
    @media (min-width: 1024px) {
      margin: 0 auto;
      grid-column: span 2;
    }
  }
}
