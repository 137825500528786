.phone {
  font-size: 14px;
  line-height: 130%;
  font-weight: 500;
}

.footer__copyright {
  font-size: clamp(10px, 1.944vw, 28px);
  line-height: 120%;
  font-weight: 700;
}

.button {
  font-size: clamp(14px, 1.25vw, 18px);
  line-height: 17px;
  font-weight: 600;
}


.gift__descr {
  font-size: clamp(12px, 1.25vw, 18px);
  line-height: 120%;
  font-weight: 400;
  color: var(--color-black);

  @media (min-width: 768px) {
    font-weight: 700;
    color: var(--color-main);
  }
}

.title {
  font-size: clamp(38px, 7.813vw, 60px);
  line-height: 110%;
  font-weight: 700;
  color: var(--color-white);
  text-align: center;

  span {
    display: block;
    font-size: 10px;
    font-size: clamp(10px, 2.344vw, 18px);
    line-height: 12px;
    letter-spacing: 0.265em;
    color: var(--color-yellow);
    text-transform: uppercase;
  }
}

.information__list-item {
  font-size: clamp(16px, 1.528vw, 22px);
  line-height: 140%;
  color: var(--color-white);
}

.h2 {
  font-size: clamp(24px, 2.917vw, 42px);
  line-height: 120%;
  font-weight: 800;
}

.consultation__title {
  font-size: clamp(24px, 2.5vw, 36px);
  line-height: 120%;
  font-weight: 800;
}

.consultation__text {
  font-size: clamp(12px, 1.528vw, 22px);
  line-height: 140%;
}

.consultation__btn {
  font-size: clamp(12px, 1.667vw, 24px);
  line-height: 120%;
  font-weight: 800;
}

.mats__details-item {
  font-size: clamp(16px, 1.389vw, 20px);
  line-height: 120%;
  color: var(--color-black);
}

.variants__title {
  font-size: clamp(20px, 1.806vw, 26px);
  line-height: 110%;
  font-weight: 700;
  color: var(--color-white);
}

.variants__descr {
  font-size: clamp(12px, 1.389vw, 20px);
  line-height: 120%;
  color: var(--color-yellow);
}

.h3 {
  font-size: clamp(24px, 2.917vw, 42px);
  line-height: 120%;
  font-weight: 800;
  text-align: center;
}

.mat__details-item {
  font-size: clamp(16px, 1.389vw, 20px);
  line-height: 120%;
}

.seo__text {
  font-size: clamp(14px, 1.111vw, 16px);
  line-height: 140%;
}

.materials__title,
.configuration__title {
  font-size: clamp(22px, 1.667vw, 24px);
  line-height: 120%;
  font-weight: 800;
}

.material__info {
  font-size: clamp(16px, 1.25vw, 18px);
  line-height: 120%;
  font-weight: 600;
}

.material__made {
  font-size: clamp(14px, 1.111vw, 16px);
  line-height: 120%;
  color: var(--color-yellow);
}

.configuration__info-type {
  font-size: clamp(16px, 1.25vw, 18px);
  line-height: 120%;
  font-weight: 600;
}

.configuration__info-pricelist {
  font-size: clamp(14px, 1.111vw, 16px);
  line-height: 120%;
}

.configuration__info-price {
  font-size: clamp(16px, 1.25vw, 18px);
  line-height: 120%;
  font-weight: 700;
  color: var(--color-yellow);
}

.choose__title {
  font-size: clamp(16px, 1.25vw, 18px);
  line-height: 120%;
  font-weight: 600;
}

.choose__made-country {
  font-size: clamp(14px, 1.111vw, 16px);
  line-height: 120%;
  color: var(--color-yellow);
}

.gifts__title {
  font-size: clamp(16px, 1.667vw, 24px);
  line-height: 120%;
  font-weight: 700;
}

.form__title {
  font-size: 20px;
  line-height: 24px;
  font-weight: 800;
  color: var(--color-black);
}

.details__info-title {
  font-size: clamp(16px, 1.25vw, 18px);
  line-height: 120%;
}

.details__info-counts {
  font-size: clamp(16px, 1.25vw, 18px);
  line-height: 120%;
  font-weight: 600;
}
