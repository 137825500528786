.details {
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  background-color: var(--color-yellow);
  border-radius: 50%;

  &__info {
    width: 100%;
    grid-column: span 2;
    margin-top: 12px;
    background-color: var(--footer-bg);
    border-radius: 4px;
  }

  &__info-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 19px 14px 11px;
    border-bottom: 1px solid rgba(49, 49, 51, 0.2);

    &:last-child {
      border-bottom: none;
    }
  }
}
