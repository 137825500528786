.header {
  &__top {
    background-color: var(--color-main);
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;

    &--top {
      display: none;

      @media (min-width: 1024px) {
        display: flex;
        padding: 15px 0;
      }
    }
  }

  &__wrapper-block {
    display: flex;
    align-items: center;
    grid-gap: 0 60px;
    color: var(--color-white);

    svg {
      fill: var(--color-white);
    }
  }

  &__adress {
    display: flex;
    align-items: center;
    grid-gap: 10px;
  }

  &__logo {
    picture,
    img {
      width: 76px;
      height: 34px;

      @media (min-width: 576px) {
        width: 92px;
        height: 40px;
      }
    }
  }

  &__contact {
    display: flex;
    align-items: center;
    grid-gap: 10px;

    span {
      display: block;
      font-size: 10px;
      line-height: 12px;
      text-align: center;
      opacity: 0.5;

      @media (min-width: 1024px) {
        display: none;
      }
    }

    svg {
      display: none;

      @media (min-width: 576px) {
        display: flex;
      }
    }
  }

  &__consultation {
    display: none;

    @media (min-width: 1024px) {
      display: flex;
      align-items: center;
      grid-gap: 10px;
      height: 41px;
      max-width: 170px;
    }
  }
}



