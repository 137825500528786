.mobile {
  position: fixed;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  background: rgba(49, 49, 51, 0.8);
  backdrop-filter: blur(5px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 5;
  overflow: hidden;

  border-bottom-left-radius: 100%;
  transition: all 0.5s;

  &.active {
    width: 100%;
    height: 100vh;
    border-bottom-left-radius: 0;

    .mobile__menu-list {
      opacity: 1;
      transform: translateY(30px);
      transition: transform 1s, opacity 0.5s;
      transition-delay: 0.3s;
    }
  }

  &__menu-list {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 10px;
    margin: 20px;
    font-size: 26px;
    font-weight: 600;
    text-align: center;
    color: var(--color-white);
    opacity: 0;
    transform: translateY(30px);
  }

  &__menu-link {
    position: relative;
    font-size: 26px;
    font-weight: 600;
    text-shadow: 0 0 0 var(--color-lusty);
    color: var(--color-white);
    transition: text-shadow 0.5s, transform 0.5s;

    &:hover {
      text-shadow: -2px 2px 0 var(--color-lusty);
      transform: translateY(-6px);
    }
  }
}
