.consultation {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 35px;

  &__title {
    max-width: 260px;
    text-align: center;
    margin: 0 auto;

    @media (min-width: 576px) {
      max-width: 100%;
      margin: 0;
    }
  }

  &__box {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-around;
    background-color: var(--color-yellow);
    box-shadow: 0px 3px 35px rgba(252, 206, 28, 0.62);
    border-radius: 8px;

    @media (min-width: 1024px) {
      grid-gap: 20px;
      padding: 0 20px;
    }
  }

  &__img {
    display: flex;
    width: 131px;
    width: 160px;
    width: clamp(130px, 11.111vw, 160px);
    flex-shrink: 0;
    position: relative;
  }

  &__image {
    position: absolute;
    display: flex;
    left: 0;
    bottom: 0;


    picture,
    img {
      width: 100%;
      height: 100%;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    grid-gap: 15px;
    padding: 20px 0;
  }

  &__btn {
    display: flex;
    align-items: center;
    grid-gap: clamp(6px, 1.042vw, 15px);

    svg {
      width: clamp(20px, 2.083vw, 30px);
      height: clamp(20px, 2.083vw, 30px);
    }
  }
}
