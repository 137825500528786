.materials {
  width: 100%;

  &__title {
    margin-bottom: 11px;
  }

  &__list {
    display: flex;
    flex-direction: column;

    @media (min-width: 1024px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 0 40px;
    }
  }

  &__list-item {
    border-bottom: 1px solid rgba(49, 49, 51, 0.2);

    &:last-child {
      border-bottom: transparent;

      @media (min-width: 1024px) {
        border-bottom: 1px solid rgba(49, 49, 51, 0.2);
      }
    }
  }
}
