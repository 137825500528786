.lang {
  position: relative;

  &__header {
    display: flex;
    align-items: center;
    grid-gap: 5px;
    cursor: pointer;
  }

  &__current {
    position: relative;
    display: flex;
    border-radius: 2px;
  }

  &__body {
    display: none;
    position: absolute;
    top: -10px;
    right: 0;
    border-radius: 8px;
    background-color:  var(--color-main);
    padding: 10px;
    z-index: 5;
  }

  &__item {
    cursor: pointer;
    transition: all 0.4s ease;

    &+& {
      margin-top: 14px;
    }

    svg {
      pointer-events: none;
    }

    &:hover {
      color: var(--color-yellow);
    }
  }
}
