:root {
  /* -- Fonts --*/
  --font-family-inter: "Inter", sans-serif; // var(--font-family-inter);

  /* -- Colors --*/
  --color-main: #313133; // var(--color-main);
  --color-yellow: #FCCE1C; // var(--color-yellow);
  --color-black: #000000;  // var(--color-black);
  --color-white: #ffffff;  // var(--color-white);
  --footer-bg: #F1F1F6; // var(--footer-bg);
  --input-bg: #DDF3F4; // var(--input-bg);


  --color-midnight: #1F2535; // var(--color-midnight);
  --color-lusty: #752B2B; // var(--color-lusty);
  --color-green: #576A21; // var(--color-green);
  --color-scampi: #6F6695; // var(--color-scampi);
  --color-pink: #C3ABAB; // var(--color-pink);
  --color-santas: #9998A4; // var(--color-santas);
  --color-buccaneer: #715050; // var(--color-buccaneer);




}








